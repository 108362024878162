1<template>
  <div class="body">
    <div class="imgs" v-if="$store.state.app.device === DEVICE_TYPE.MOBILE">
      <img style="width: 100%" src="./image/wxMobileLogin.jpg" alt="" srcset="" />
    </div>
    <div v-else class="login-box">
      <div class="login-info">
        <div class="login-info-top">
          <div class="login-info-left">
            <img src="./image/bgm4.png" alt="" />
          </div>
          <div class="login-info-right">
            <span class="login-info-title">
              公文流转系统
              <span>Document circulation system</span>
            </span>
            <div class="login-info-wxlogin-box" v-if="wxOpenUrl">
              <span class="login-info-tap">请授权企业微信第三方登录,获取更好体验</span>
              <span class="login-info-wxlogin" @click="goLoginWx">企业微信扫码登录 <span></span></span>
            </div>
          </div>
        </div>

        <div class="login-info-bottom">
          <div class="login-info-tool"><span>帮助</span><span>隐私</span><span>条款</span></div>
          <span class="login-info-web">Copyright 2022 贵州遐宇科技服务有限公司 <a href="https://beian.miit.gov.cn/">黔ICP备20006285号-1</a> All rights reserved. </span>
        </div>
      </div>
    </div>
    <isExpiredTipDialog ref="isExpiredTipDialog" />
  </div>
</template>



<script>
import { serverUrl } from '@/mixins/config.js'
import { DEVICE_TYPE } from '@/utils/device'
import { mapActions } from 'vuex'
import isExpiredTipDialog from './components/isExpiredTipDialog.vue'
export default {
  components: {
    isExpiredTipDialog,
  },
  data() {
    return {
      code: null,
      type: null,
      re: '',
      wxOpenUrl: null,
      DEVICE_TYPE,
    }
  },
  created() {
    this.getUrlPramas()
  },
  methods: {
    ...mapActions(['wxLogin', 'dictTypeData']),
    qywxlogin() {
      const para = {
        loginType: this.type,
        authCode: this.code,
      }
      this.wxLogin(para)
        .then((res) => {
          const { openFlag, isExpired } = res
          if (isExpired) {
            this.$refs.isExpiredTipDialog.open({ openFlag })
            return
          }
          if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {
            const { path, query } = this.getQueryInfo()
            switch (path) {
              case '/fileStream_Approval_Manage':
                this.$router.push({ name: 'mobileApproval', query })
                break
              case '/fileStream_Approval_process':
                this.$router.push({ name: 'showMobileApproval', query })
                break
              case '/fileStream_documentSend':
                this.$router.push({ name: 'mobileBillDetail', query })
                break
              default:
                this.$router.push({ path: '/mobile/home' })
            }
          } else {
            this.$router.push({ path: '/fileStreamHome' })
          }
          // this.$router.push({ path: '/fileStreamHome' })
          // 加载字典所有字典到缓存中
          this.dictTypeData().then((res) => {})
        })
        .catch((err) => {
          console.log('err+++++++++', err)
        })
    },
    getUrlPramas() {
      /**
       * 获取url 的wx登录链接code,无则 需要微信登录
       *
       *
       */
      const w = new URLSearchParams(decodeURIComponent(window.location.search))
      const re = w.get('redirect')
      this.re = re
      const aut = new URLSearchParams(re.substring(re.indexOf('?')))
      const code = aut.get('auth_code') || aut.get('code')
      if (aut.get('code')) {
        // 企业微信端
        this.type = 'APP'
      } else if (aut.get('auth_code')) {
        // pc端
        this.type = 'PC'
      }
      this.code = code
      console.log('url===========', window.location.url)
      console.log('code===========', code, this.type, re)
      if (!code) {
        if (re && re != '/') {
          this.wxOpenUrl = encodeURIComponent(`http://${serverUrl}` + re)
        } else {
          this.wxOpenUrl = encodeURIComponent(`http://${serverUrl}/`)
        }
        // this.$message.error('请退出后重新进入应用！')
        return
      }
      this.qywxlogin()
    },
    getQueryInfo() {
      const res = this.re.split('?')
      const query = {}
      const entries = new URLSearchParams(res[1]).entries()
      for (const [key, value] of entries) {
        query[key] = value
      }
      return {
        path: res[0],
        query,
      }
    },
    goLoginWx() {
      console.log('url====', this.wxOpenUrl)
      window.open(
        `https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=wwdbef0913f3a4e57c&redirect_uri=${this.wxOpenUrl}&state=web_login@gyoss9&usertype=member`,
        '_self'
      )
    },
  },
}
</script>
<style lang="less" scoped>
#userLayout.user-layout-wrapper .container {
  background: none;
}
.body {
  width: 100%;
  height: 100%;
  background-color: #333333;
  .imgs {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
  }
}
div,
img,
span {
  box-sizing: border-box;
}
.login-box {
  width: 100%;
  height: 100%;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./image/bgm1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.login-info {
  width: 1200px;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url('./image/bgm5.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.login-info-top {
  display: flex;
  width: 100%;
  height: 100%;
}

.login-info-left {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.login-info-left img {
  width: 493px;
  height: 430px;
  margin-top: 80px;
}

/**  右边  */
.login-info-right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 80px 0 100px 80px;
}
.login-info-right .login-info-wellocome {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0067ff;
  display: flex;
  flex-direction: column;
}
.login-info-right .login-info-line {
  width: 80px;
  height: 2px;
  background: #0067ff;
  margin-top: 30px;
}
.login-info-right .login-info-title {
  font-size: 80px;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  color: #333333;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  flex-direction: column;
}
.login-info-right .login-info-title span {
  font-size: 26px;
  padding-top: 20px;
}

.login-info-right .login-info-title1 {
  font-size: 80px;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  color: #333333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-info-right .login-info-wxlogin-box {
  width: 100%;
  height: 113px;
  position: relative;
}
.login-info-right .login-info-tap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px 0;
  color: #ccc;
}
.login-info-right .login-info-wxlogin {
  font-size: 40px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #ffffff;
  width: 490px;
  height: 113px;
  background-image: url('./image/bgm6.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  position: absolute;
  right: -12px;
  z-index: 2;
  cursor: pointer;
}
.login-info-right .login-info-wxlogin span {
  width: 33px;
  height: 24px;
  display: inline-block;
  background-image: url('./image/bgm7.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 47px;
}

/**  底部网站说明  */
.login-info-bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.login-info-bottom .login-info-tool {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ccc;
  line-height: 36px;
}
.login-info-bottom span {
  cursor: pointer;
}
.login-info-bottom .login-info-tool > span:nth-child(2) {
  padding: 0 30px;
}
.login-info-bottom .login-info-web {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ccc;
  line-height: 36px;
}
</style>